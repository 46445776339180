<template>
  <div>
    <b-card>
      <validation-observer
        ref="form"
        v-slot="{invalid}"
      >
        <b-card v-if='!isDataLoaded'>
          <div class='text-center text-primary my-2'>
            <b-spinner class='align-middle' />
          </div>
        </b-card>
        <b-form v-else
          novalidate
          @submit.prevent="onSubmit"
        >
          <b-row>
            <!-- name -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.name')"
                vid="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('labels.name')"
                >
                  <b-form-input
                    v-model="form.name"
                    :placeholder="$t('labels.name')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- email -->
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.email')"
                vid="email"
                rules="required|email"
              >
                <b-form-group
                  :label="$t('labels.email')"
                >
                  <b-form-input
                    v-model="form.email"
                    type="email"
                    :placeholder="$t('labels.email')"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- password -->
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.password')"
                vid="password"
                :rules="isEdit?'':'required|min:6'"
              >
                <b-form-group
                  :label="$t('labels.password')"
                >
                  <b-form-input
                    v-model="form.password"
                    type="password"
                    :placeholder="$t('labels.password')"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('labels.confirm_password')"
                vid="confirm_password"
                :rules="isEdit?'':'required|min:6|confirmed:password'"
              >
                <b-form-group
                  :label="$t('labels.confirm_password')"
                >
                  <b-form-input
                    v-model="form.password_confirmation"
                    type="password"
                    :placeholder="$t('labels.confirm_password')"
                    autocomplete="new-password"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              md="6"
              cols="12"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="roles"
                :name="$t('models.roles')"
                rules="required"
              >
                <b-form-group
                  :label="$t('models.roles')"
                >
                  <v-select
                    v-model="form.roles"
                    :dir="$store.state.appConfig.layout.direction"
                    multiple
                    label="title"
                    :options="roles"
                    :reduce="item => item.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="country_id"
                :name="$t('country')"
                rules="required"
              >
                <b-form-group
                  :label="$t('country')"
                >
                  <v-select
                    v-model="form.country_id"
                    :dir="$store.state.appConfig.layout.direction"
                    label="name_label"
                    :options="countries"
                    :reduce="item => item.id"
                    :placeholder="$t('country')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                vid="image"
                :name="$t('labels.image')"
                rules="required"
              >
                <ImagePreview
                  v-model="image"
                  :label="$t('labels.image')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-col>

            <!-- checkbox -->
            <b-col cols="12">
              <b-card-text class="mb-0">
                {{ $t('labels.status') }}
              </b-card-text>
              <b-form-checkbox
                v-model="form.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
            </b-col>

            <!-- submit and reset -->
            <b-col cols="12 text-right">
              <LoadingButton />
              <b-button
                type="reset"
                variant="outline-secondary"
              >
                {{ $t('labels.reset') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'

export default {
  mixins: [formMixin],
  data() {
    return {
      isDataLoaded:false,
      roles: [],
      countries: [],
      image: null,
      form: {
        name: null,
        email: null,
        password: null,
        password_confirmation: null,
        status: 'active',
        image: null,
        roles: null,
      },
    }
  },
  watch: {
    image(val) {
      if (val) {
        this.form.image = val
      } else {
        this.form.image = 'delete'
      }
    },
  },
  created() {
    this.getRoles()
    this.getCountries()
    if (this.isEdit) {
      this.loadData()
    }else {
      this.isDataLoaded = true
    }
  },
  methods: {
    getRoles() {
      this.axios.get('/lists/role')
        .then(res => {
          this.roles = res.data
        })
    },
    getCountries() {
      this.axios.get('/lists/Country')
        .then(res => {
          this.countries = res.data
        })
    },
    loadData() {
      this.axios.get(`/${this.resource}/${this.resourceId}`)
        .then(res => {
          const { data } = res.data
          this.form = {
            name: data.name,
            email: data.email,
            status: data.status,
            country_id: data.country_id,
            roles: data.roles.map(item => item.id),
          }
          this.image = data.image
        })
        .catch(() => {
          this.isDataLoaded = true
        })
        .finally(() => {
          this.isDataLoaded = true
        });

    },
    loadFormData() {
      let $form = _.cloneDeep(this.form)
      $form = {
        ...$form,
      }
      if (!($form.image instanceof File)) {
        delete $form.image
      }
      return $form
    },
  },

}
</script>
